import React from 'react'
import IndustryImageDescription from '../Components/General/IndustryImageText'
import MainLayout from '../layouts/Main'
import '../template-styles/culture-insights-hub.scss'
import Arrow from '../Components/Arrow/Arrow'
import Carousel from 'react-multi-carousel';
import Divider from '../Components/General/Divider/Divider'
import { Helmet } from 'react-helmet'


const responsiveCarousel = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 2561 },
    items: 5,
    slidesToSlide: 5
  },
  desktop: {
    breakpoint: { max: 2560, min: 1300 },
    items: 3,
    slidesToSlide: 4
  },
  tablet: {
    breakpoint: { max: 1299, min: 887 },
    items: 2,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 887, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

function ConsumerInsightsHub( {data} ) {
    const sections = data?.wordPress?.hPageTemplates?.nodes?.[0]?.hPages?.nodes[0].consumerInsightsHubFields.fields


  return (
    <MainLayout
    headerPattern="/images/patterns/pattern-19.svg"
    footerPattern="/images/patterns/pattern-02.svg"
    >
            <Helmet>
        <title>Consumer Insights Hub: Explore Key Marketing Trends</title>
        <meta
          name="description"
          content="Dive into the Consumer Insights Hub to uncover essential marketing trends, strategies, and data-driven insights to engage your audience effectively."
        />
        <meta
          name="keywords"
          content="Consumer Insights Hub, marketing trends, audience engagement, data-driven insights, marketing strategies, consumer behavior"
        />
        <meta property="og:title" content="Consumer Insights Hub: Explore Key Marketing Trends" />
        <meta
          property="og:description"
          content="Dive into the Consumer Insights Hub to uncover essential marketing trends, strategies, and data-driven insights to engage your audience effectively."
        />
        <meta property="og:url" content="https://www.wunderkind.co/knowledge-hub/consumer-insights-hub-main-page/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Consumer Insights Hub: Explore Key Marketing Trends" />
        <meta
          name="twitter:description"
          content="Dive into the Consumer Insights Hub to uncover essential marketing trends, strategies, and data-driven insights to engage your audience effectively."
        />
        <link rel="canonical" href="https://www.wunderkind.co/knowledge-hub/consumer-insights-hub-main-page/" />
      </Helmet>
      {sections.map((section, index) => {
        const type = section?.__typename
        switch (type) {
          case "WordPress_HPage_Consumerinsightshubfields_Fields_ImageAndTextSection":
          return (
            <>
            <section style={{backgroundColor: section?.wrapperBackgroundColor || `#191919`}}>
              <div className="cir-image-text-header-and-image-container">

              <div className="cir-image-text-container">
                <h1 className='cir-image-text-header'>{section?.mainHeader}</h1>
                <p>{section?.description}</p>
                <p>{section?.descriptionWithImage}
                <img src={section?.imageWithDescription.sourceUrl} alt=''/>    
                 </p>
              </div>
              <div className="cir-image-container">
                <img src={section?.mainImage.sourceUrl} alt='' />
              </div>
              </div>
            </section>
            <Divider bgColor="#ffa760" maskColor="#191919" direction="bottom-right" />
            </>
          );

          case "WordPress_HPage_Consumerinsightshubfields_Fields_Video":
            return (
              <>
                <section style={{backgroundColor: section?.wrapperBackgroundColor || `#FE8422`}}>
                    <div className="cir-iframe-container">
                      <div className="iframe-container">
                        <iframe
                            src={section?.video}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded Video"
                            />
                            </div>
                    </div>
                </section>
                <div style={{ position: "relative" }}>
                <div
                  className="skewed-divider"
                  style={{ backgroundColor: `#ffc799` }}
                  />
              </div>
                  </>
            );

            case "WordPress_HPage_Consumerinsightshubfields_Fields_ThreeShortStatsCards":
              return (
                <>
                <section key={index} style={{padding: '2rem', backgroundColor: section?.wrapperBackgroundColor}}>
                  <div className='cih-three-short-cards-container'>
                    <h2 className='cir-three-short-cards-main-header'>{section?.header}</h2>
                    {section?.cardsRepeat?.map((card, cardIndex) => (
                      <div
                      className='cih-three-shorts-card'
                      key={cardIndex}
                      style={{
                        backgroundColor: card.cardBackgroundColor,
                        color: card.cardTextColor || `#FFFFFF`,
                      }}
                      >
                        <h3>{card.headerStat}</h3>
                        <p>{card.description}</p>
                      </div>
                    ))}
                  </div>
                </section>
                <Divider bgColor="#ffc799" maskColor="#f5ebe1" direction="top-left" />
                </>
              );
            case "WordPress_HPage_Consumerinsightshubfields_Fields_ThreeRegionalReportsCards":
              return (
                <>
                <section key={index} style={{padding: '2rem', backgroundColor: section?.wrapperBackgroundColor, position: 'relative', zIndex: 1}}>
                  <div className="cih-three-regional-reports-container">
                    <h2 className='cir-three-regional-reports-main-header'>{section?.header}</h2>
                    <h3 className='cir-three-regional-reports-secondary-header'>{section?.secondaryHeader}</h3>
                  {section?.cardsRepeat?.map((card) => (
                    <div
                    style={{backgroundColor: card?.cardBackgroundColor, color: card?.cardTextColor}}
                    className='cih-three-regional-reports-card'>
                      {/* <h6>{card.cardCategory}</h6> */}
                        <img src={card.cardImage?.sourceUrl} alt=''/>
                    <h3>{card.cardHeader}</h3>
                    <h5>{card.cardDescription}</h5>
                    <a target="_blank" href={card.url}><Arrow  className="cih-three-regional-reports-arrow" dark/></a>
                    </div>
                  ))}
                  </div>
                </section>
 
                  </>

              );
            case "WordPress_HPage_Consumerinsightshubfields_Fields_ThreeWebinarsCards":
              return (
                <section key={index} style={{padding: '2rem', backgroundColor: section?.wrapperBackgroundColor}}>
                  <div className="cih-three-regional-reports-container">
                    <h2 className='cir-three-regional-reports-main-header'>{section?.header}</h2>
                    <h3 className='cir-three-regional-reports-secondary-header'>{section?.secondaryHeader}</h3>
                    {section?.cardsRepeat?.map((card) => (
                    <div
                    style={{backgroundColor: card?.cardBackgroundColor, color: card?.cardTextColor}}
                    className='cih-three-regional-reports-card'>
                      {/* <h6>{card.cardCategory}</h6> */}
                        <img src={card.cardImage?.sourceUrl} alt=''/>
                    <h3>{card.cardHeader}</h3>
                    <h5>{card.cardDescription}</h5>
                    <a target="_blank" href={card.url}><Arrow  className="cih-three-regional-reports-arrow" dark/></a>
                    </div>
                  ))}
                  </div>
                </section>
              );
            // case "WordPress_HPage_Consumerinsightshubfields_Fields_NineVerticalReportsCards":
            //   return (
            //     <section style={{backgroundColor: section?.wrapperBackgroundColor}}>
            //                           <h2>{section?.header}</h2>
            //       <div className='cih-three-short-cards-container'>
            //         {section?.cardsRepeat?.map((card, cardIndex) => (
            //           <div
            //           className='cih-three-shorts-card'
            //             key={cardIndex}
            //             style={{
            //               backgroundColor: card.cardBackgroundColor,
            //               color: card.cardTextColor,
            //             }}
            //           >
            //             <h3>{card.headerStat}</h3>
            //             <p>{card.description}</p>
            //           </div>
            //         ))}
            //       </div>
            //     </section>
            //   );
            // case "WordPress_HPage_Consumerinsightshubfields_Fields_OtherResourcesCards":
            //     return (
            //       <section style={{backgroundColor: section?.wrapperBackgroundColor}}>
            //                                               <h2>{section?.header}</h2>
            //       <div className='cih-three-short-cards-container'>
            //         {section?.cardsRepeat?.map((card, cardIndex) => (
            //           <div
            //           className='cih-three-shorts-card'
            //             key={cardIndex}
            //             style={{
            //               backgroundColor: card.cardBackgroundColor,
            //               color: card.cardTextColor,
            //             }}
            //           >
            //             <h3>{card.headerStat}</h3>
            //             <p>{card.description}</p>
            //           </div>
            //         ))}
            //       </div>
            //       </section>
            //     )
          default:
            return <></>;
        }
      })}
    </MainLayout>  )
}

export const query = graphql`
query ConsumerInsightsTemplate($title: String = "title") {
  wordPress {
    hPageTemplates(where: {slug: "consumer-insights-hub"}) {
      nodes {
        hPages(where: {title: $title}) {
            nodes {
                consumerInsightsHubFields {
                    fields {
                        ... on WordPress_HPage_Consumerinsightshubfields_Fields_ImageAndTextSection {
                        description
                        descriptionWithImage
                        imageWithDescription {
                            sourceUrl
                        }
                        mainHeader
                        mainImage {
                            sourceUrl
                        }
                        wrapperBackgroundColor
                        }
                        ... on WordPress_HPage_Consumerinsightshubfields_Fields_Video {
                        video
                        wrapperBackgroundColor
                        }
                        ... on WordPress_HPage_Consumerinsightshubfields_Fields_ThreeShortStatsCards {
                        fieldGroupName
                        header
                        cardsRepeat {
                            cardBackgroundColor
                            cardTextColor
                            description
                            headerStat
                        }
                        wrapperBackgroundColor
                        }
                        ... on WordPress_HPage_Consumerinsightshubfields_Fields_ThreeRegionalReportsCards {
                        fieldGroupName
                        header
                        secondaryHeader
                        cardsRepeat {
                            cardBackgroundColor
                            cardCategory
                            cardCategoryBackgroundColor
                            cardCategoryTextColor
                            cardHeader
                            cardTextColor
                            cardDescription
                            url
                            cardImage {
                            sourceUrl
                            }
                        }
                        wrapperBackgroundColor
                        }
                        ... on WordPress_HPage_Consumerinsightshubfields_Fields_ThreeWebinarsCards {
                        fieldGroupName
                        header
                        secondaryHeader
                        cardsRepeat {
                            cardBackgroundColor
                            cardCategory
                            cardCategoryBackgroundColor
                            cardCategoryTextColor
                            cardHeader
                            cardDescription
                            url
                            cardImage {
                            sourceUrl
                            }
                            cardTextColor
                        }
                        wrapperBackgroundColor
                        }
                        ... on WordPress_HPage_Consumerinsightshubfields_Fields_NineVerticalReportsCards {
                        fieldGroupName
                        header
                        wrapperBackgroundColor
                        cardsRepeat {
                            cardBackgroundColor
                            cardCategory
                            cardCategoryBackgroundColor
                            cardCategoryTextColor
                            cardImage {
                            sourceUrl
                            }
                            cardTextColor
                        }
                        }
                        ... on WordPress_HPage_Consumerinsightshubfields_Fields_OtherResourcesCards {
                        fieldGroupName
                        header
                        wrapperBackgroundColor
                        cardsRepeat {
                            cardBackgroundColor
                            cardCategory
                            cardCategoryBackgroundColor
                            cardCategoryTextColor
                            cardImage {
                            sourceUrl
                            }
                            cardTextColor
                        }
                        }
              }
            }
            }
        }
      }
    }
  }
}

`;

export default ConsumerInsightsHub